/* Marquee.css */

.marquee {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
  }
  
  .track {
    display: flex;
    animation: scroll 40s linear infinite; /* Adjust the speed (20s) to fit the width of your text */
  }
  
  .track span {
    color: white;
    font-weight: bold;
    font-size: 1rem;
    padding-right: 50px; /* Spacing between the items */
  }
  
  /* Keyframes for smooth scrolling */
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  