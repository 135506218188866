.slava-marquee {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    height: 40px; /* Matching the height of the other marquee */
    padding: 0; /* Adjust padding if necessary to vertically center content */
    border-top: 2px solid black;
    border-bottom: 2px solid black;
  }
  
  .slava-track {
    display: flex;
    animation: slava-scroll 40s linear infinite; /* Adjust speed to fit the width of your text */
  }
  
  .slava-item {
    display: flex;
    padding-right: 45px; /* Spacing between items */
    height: 100%; /* Ensuring items take full height */
  }
  
  @keyframes slava-scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  